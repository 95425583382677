<template>
  <profile-page tab="informacion" />
</template>

<script>
import ProfilePage from "@views/investor/profile/ProfilePage";
export default {
  name: "ProfileRootPage",
  components: { ProfilePage }
}
</script>

<style scoped>

</style>